import React from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import FormularioContactoPrensa from "../components/formularioContactoPrensa"
import styled from "styled-components"

const StyledLink = styled((props) => <Link {...props} />)`
    color: white;
    box-shadow: none;
    font-size: 2rem;
    line-height: 1;
`

const ModalContactoPrensaPage = () => (
    <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => (
            <div>
                {modal ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            height: "25px",
                            marginTop: "-10px"
                        }}
                    >
                        <StyledLink to={closeTo}>
                            <span aria-hidden="true">&times;</span>
                        </StyledLink>
                    </div>
                ) : (
                    <header>
                        <h1>Contáctanos</h1>
                    </header>
                )}

                <FormularioContactoPrensa />
            </div>
        )}
    </ModalRoutingContext.Consumer>
)

export default ModalContactoPrensaPage
